<template>
  <validation-observer tag="div" ref="form" class="form-container">
    <header class="header">
      <back-button label="Respuestas" to="/written-answers" />
      <h1>Nueva Respuesta</h1>
    </header>

    <!-- <button @click="checkAll">CheckAll</button>
    <button @click="onSubmit">
      {{ isLoading ? 'Cargando...' : 'Enviar' }}
    </button> -->

    <form @submit.prevent="onSubmit" class="form">
      <!-- <custom-button type="submit" variant="primary" :disabled="isLoading">
        {{ isLoading ? "Cargando..." : "Enviar" }}
      </custom-button> -->

      <span class="subtitle">Filtros</span>

      <div
        v-for="(feature, index) in features"
        :key="feature.id"
        class="question-container"
      >
        <div class="index">{{ index + 1 }}</div>
        <radio-button-group
          :label="feature.name"
          :options="filterOptions(feature)"
          v-model="feature.answer"
          rules="required"
        />
      </div>

      <span class="subtitle">Preguntas</span>

      <div
        class="question-container"
        v-for="(answer, index) in answers"
        :key="answer.question.id"
      >
        <div class="index">{{ index + 1 }}</div>

        <ENPSScale
          v-if="answer.section.id === 'enps'"
          v-model="answer.answer"
          :label="answer.question.text"
          rules="required"
          size="sm"
        />

        <div
          v-else-if="typeof answer.answer === 'string'"
          class="textarea-container"
        >
          <h4>{{ answer.question.text }}</h4>
          <custom-textarea v-model="answer.answer" />
        </div>

        <radio-button-group
          v-else
          :label="answer.question.text"
          :groupName="answer.question.id"
          :options="scale.map(({ id, text }) => ({ id, text }))"
          rules="required"
          :picked="answer.answer"
          @change="answer.answer = $event"
        />
      </div>

      <custom-button type="submit" variant="primary" :disabled="isLoading">
        {{ isLoading ? "Cargando..." : "Enviar" }}
      </custom-button>
    </form>
  </validation-observer>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import { ValidationObserver } from "vee-validate";

import CustomButton from "@/components/CustomButton.vue";
import RadioButtonGroup from "@/components/RadioButtonGroup.vue";
import ENPSScale from "../../components/ENPSScale.vue";
import CustomTextarea from "../../components/CustomTextarea.vue";
import BackButton from "../../components/BackButton.vue";

export default {
  name: "NewAnswer",

  components: {
    CustomButton,
    ValidationObserver,
    RadioButtonGroup,
    ENPSScale,
    CustomTextarea,
    BackButton
  },

  data: () => ({
    features: [],
    answers: [],
    isLoading: false
  }),

  computed: {
    ...mapState({
      questions: state => state.sections.questions,
      sections: state => state.sections.sections,
      filters: state => state.filters.filters,
      survey: state => state.survey,
      writtenAnswers: state => state.writtenAnswers,
      scale: state => state.scale.scale
    })
  },

  methods: {
    ...mapActions(["addWrittenAnswer"]),
    ...mapActions("sections", ["fetchQuestions", "fetchSections"]),
    ...mapActions("filters", ["fetchFilters"]),
    ...mapActions("scale", ["fetchScale"]),

    ...mapMutations(["setAlert"]),

    async setupForm() {
      try {
        await this.fetchFilters();
        await this.fetchQuestions();
        await this.fetchSections();
        await this.fetchScale();

        this.answers = this.questions.map(q => ({
          section: q.section,
          question: {
            id: q.id,
            text: q.text
          },
          answer:
            q.sectionId === "enps"
              ? -1
              : q.isOpenQuestion
              ? ""
              : {
                  id: "",
                  text: ""
                }
        }));

        this.features = this.filters.map(f => ({
          ...f,
          answer: "",
          options: f.options.map(o => ({ ...o, id: o.name }))
        }));
      } catch (err) {
        console.log(err);
      }
    },

    async onSubmit() {
      try {
        this.isLoading = true;
        const valid = await this.$refs.form.validate();

        if (!valid) {
          return this.setAlert({
            state: "error",
            message:
              "Debe responder todas las preguntas antes de enviar la encuesta"
          });
        }

        // for (let i = 0; i < 10; i++) {
        await this.addWrittenAnswer({
          features: this.features.reduce((obj, f) => {
            obj[f.name] =
              typeof f.answer === "object"
                ? f.answer.value || f.answer.name
                : f.answer;
            return obj;
          }, {}),
          answers: this.answers
        });
        // }

        this.$router.push("/written-answers");
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },

    checkAll() {
      this.answers.forEach(answer => {
        let ran = Math.floor(Math.random() * (this.scale.length - 0) + 0);
        if (
          !(
            typeof answer.answer === "string" || answer.answer instanceof String
          ) &&
          answer.section.id !== "enps"
        )
          answer.answer = {
            id: this.scale[ran].id,
            text: this.scale[ran].text
          };
        if (answer.section.id === "enps")
          answer.answer = Math.floor(Math.random() * (11 - 0) + 0);
      });

      this.features.forEach(feature => {
        let options = this.filterOptions(feature);
        let random = Math.floor(Math.random() * (options.length - 0) + 0);
        console.log(random);
        feature.answer = options[random];
      });
    },

    filterOptions(filter) {
      if (!filter.hasParent) return [...filter.options, "N/A"];

      const parent = this.features.find(f => f.id === filter.parentId);

      if (!parent || !parent.answer || parent.answer === "N/A")
        return [...filter.options, "N/A"];

      return [
        ...filter.options.filter(o => o.parentOption === parent.answer.id),
        "N/A"
      ];
    }
  },

  mounted() {
    this.setupForm();
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.survey.active) next("/");
      else next();
    });
  }
};
</script>

<style scoped>
.form-container {
  background-color: #fafafa;
}

.form {
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  padding: var(--lengthLg3);
  display: flex;
  flex-direction: column;
  grid-gap: var(--lengthLg2);
  gap: var(--lengthLg2);
  background-color: white;
  border-radius: var(--lengthSm2);
  box-shadow: 1px 1px 5px 1px var(--grayColor2);
}

.header {
  max-width: 980px;
  width: 100%;
  margin: 0 auto var(--lengthSm3);
  display: flex;
  flex-direction: column;
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}

.subtitle {
  color: var(--fontColor3);
  font-size: 0.75rem;
  font-weight: var(--medium);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.question-container {
  display: flex;
  align-items: flex-start;
  grid-gap: var(--lengthMd2);
  gap: var(--lengthMd2);
}

.index {
  width: 28px;
  height: 28px;
  display: grid;
  place-items: center;
  color: white;
  font-weight: var(--medium);
  background-color: var(--mainColor1);
  border-radius: 50%;
}

.textarea-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  grid-gap: var(--lengthSm3);
  gap: var(--lengthSm3);
}
</style>
